.primary {
  color: var(--primary-red);
}

.secondary {
  color: var(--secondary-dark);
}

.tertiary {
  color: var(--surface-dark);
}

.displayHeading {
  font-weight: 700;
  font-style: normal;
  font-family: var(--font-family-secondary);
  line-height: 105%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.displayHeadingLarge {
  font-size: 48px;
}

.displayHeadingMedium {
  font-size: 32px;
}

.displayHeadingSmall {
  font-size: 24px;
}

.heading {
  font-weight: 700;
  font-style: normal;
  font-family: var(--font-family-primary);
  letter-spacing: -0.02em;
}

.desktopH1 {
  font-size: 80px;
  line-height: 88px;
  letter-spacing: -0.04em;
}

.desktopH2 {
  font-size: 56px;
  line-height: 56px;
  letter-spacing: -0.04em;
}

.h1 {
  font-size: 48px;
  line-height: 48px;
}

.h2 {
  font-size: 40px;
  line-height: 40px;
}

.h3 {
  font-size: 32px;
  line-height: 35px;
}

.h4 {
  font-size: 28px;
  line-height: 34px;
}

.h5 {
  font-size: 24px;
  line-height: 26px;
}

.h6 {
  font-size: 18px;
  line-height: 20px;
}

.body {
  font-weight: 400;
  font-style: normal;
  font-family: var(--font-family-primary);
}

.bodyText {
  font-size: 16px;
  line-height: 22px;
}

.bodyTextBold {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
}

.bodySmall {
  font-size: 14px;
  line-height: 18px;
}

.bodySmallBold {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.01em;
}

.CTA {
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  font-family: var(--font-family-primary);
  line-height: 21px;
}

.meta {
  font-style: normal;
  font-size: 12px;
  font-family: var(--font-family-primary);
  line-height: 16px;
  letter-spacing: -0.01em;
}

.metaText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.metaTextBold {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.link {
  font-style: normal;
  font-family: var(--font-family-primary);
  letter-spacing: -0.02em;
  text-decoration: underline;
}

.linkTextBold {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.linkTextSmallBold {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

@media (max-width: 1024px) {
  .desktopH1:not(.strict) {
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.02em;
  }

  .desktopH2:not(.strict) {
    font-size: 32px;
    line-height: 34px;
    letter-spacing: -0.02em;
  }

  .h1:not(.strict) {
    font-size: 32px;
    line-height: 35px;
  }

  .h2:not(.strict) {
    font-size: 32px;
    line-height: 35px;
  }

  .h3:not(.strict) {
    font-size: 18px;
    line-height: 20px;
  }

  .h5:not(.strict) {
    font-size: 16px;
    line-height: 19px;
  }

  .h6:not(.strict) {
    font-size: 16px;
    line-height: 19px;
  }

  .bodyText:not(.strict) {
    font-size: 14px;
    line-height: 18px;
  }

  .bodyTextBold:not(.strict) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
  }
}

@media (max-width: 576px) {
  .desktopH1:not(.strict) {
    font-size: 32px;
    line-height: 35px;
    letter-spacing: -0.02em;
  }
}
