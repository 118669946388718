.skeleton {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 8px;
  background: #ddd;
}

.skeletonLoading::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  height: 85px;
  background: #fff;
}

.skeletonText,
.skeletonText::after {
  position: absolute;
  background: #ddd;
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.skeletonText {
  bottom: 55px;
  left: 20%;
  z-index: 1;
  width: 60%;
  height: 18px;
  border-radius: 8px;
}

.skeletonText::after {
  content: '';
  top: 30px;
  left: 5%;
  width: 90%;
  height: 10px;
  border-radius: 8px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  25% {
    opacity: 0.5;
  }
}
