.container {
  display: flex;
  margin: 20px 0;
  background: right bottom no-repeat;
  background-size: contain;
  cursor: pointer;
  user-select: none;
}

.desktop {
  display: none;
}

.innerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
}

.imageContainer {
  position: relative;
  right: 0;
  transition: all 250ms ease;
}

.container:hover .imageContainer {
  transform: scale(1.05) rotate(2deg);
}

.contentContainer {
  padding-left: 14px;
}

.text {
  display: block;
  margin-top: 8px;
}

.cta {
  padding-top: 16px;
}

@media (min-width: 576px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .innerBox {
    margin-top: 32px;
    margin-bottom: 32px;
    padding-left: 40px;
    border-radius: 16px;
    background-color: var(--boxColor);
  }

  .imageContainer {
    margin-top: -32px;
    margin-bottom: -32px;
    padding-left: 40px;
  }
}

@media (min-width: 1200px) {
  .contentContainer {
    flex-shrink: 0;
    max-width: 50%;
    padding: 0;
  }
}
