.header {
  padding-bottom: 34px;
}

.subtitleContainer {
  margin-top: 34px;
}

@media (min-width: 768px) {
  .header {
    text-align: center;
  }
}
