.wrapper {
  display: flex;
  overflow-x: scroll;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.wrapper > *:first-child {
  margin-left: 20px;
}
