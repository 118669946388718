.card {
  position: relative;
  display: block;
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  font-weight: normal;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.reviewMeta * {
  cursor: pointer;
}

.text {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 130%;
}
