.wrapper {
  margin: 20px;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background: #5dace8;
  color: white;
}

.header {
  padding: 4px 0 24px;
}

.title {
  margin-bottom: 5px;
  color: white;
}

.description {
  font-size: 14px;
  line-height: 18px;
}

.profileWrapper {
  margin-bottom: 10px;
}

.footer {
  padding-top: 16px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
}
