.wrapper {
  margin-bottom: 40px;
}

.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 20px;
}

.scrollWrapper {
  display: flex;
  overflow-x: scroll;
  text-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollWrapper::-webkit-scrollbar {
  display: none;
}

.scrollWrapper > *:first-child {
  margin-left: 20px;
}

.scrollWrapper::after {
  content: ' ';
  display: block;
  flex-shrink: 0;
  width: 10px;
  height: 1px;
}

.cardWrapper {
  display: flex;
  flex-shrink: 0;
  width: 200px;
  margin-right: 10px;
  border-radius: 10px;
}

@media screen and (min-width: 769px) {
  .header {
    padding-right: 0;
    padding-left: 0;
  }

  .cardWrapper:first-child {
    margin-left: 0;
  }

  .scrollWrapper::after {
    display: none;
  }

  .cardWrapper:last-of-type {
    margin-right: 0;
  }
}
