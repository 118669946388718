.aboutMemmoHeader {
  padding: 0 16px;
}

.aboutMemmoHeading {
  font-size: 20px;
  line-height: 18px;
}

@media screen and (min-width: 1024px) {
  .aboutMemmoHeader {
    padding: unset;
  }
}
