.coloredBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: -moz-available;
  width: -webkit-fill-available;
  min-height: 90px;
  margin: 45px 0;
  margin: unset;
  padding: 5px 10px;
  border-radius: 16px;
  background-position-x: 90%;
  background-position-y: 50%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  text-shadow: none;
  user-select: none;
}

.coloredBanner > h2 {
  display: block;
}

.coloredBannerTitle {
  display: inherit;
  margin-bottom: 5px;
  color: #fff;
  font-size: 24px;
  white-space: pre-line;
  transition: all 0.3s ease-in-out;
}

.clickableBanner {
  display: flex;
  width: -moz-available;
  width: -webkit-fill-available;
  cursor: pointer;
}

@media (hover: hover) {
  .clickableBanner:hover .coloredBannerTitle {
    transition: all 0.3s ease-in-out;
    scale: 1.05;
  }
}

@media only screen and (min-width: 768px) {
  .coloredBanner {
    min-height: 140px;
  }

  .coloredBannerTitle {
    font-size: calc(24px + 6 * ((100vw - 320px) / 680));
  }
}

@media only screen and (min-width: 1024px) {
  .coloredBannerTitle {
    font-size: 40px;
  }
}
