.wrapper {
  padding: 16px 0;
  border-top: 1px rgba(39, 41, 45, 0.1) solid;
  color: var(--colors-secondary-900);
}

.wrapper:last-child {
  border-bottom: 1px rgba(39, 41, 45, 0.1) solid;
}

.text {
  width: 85%;
  padding: 16px 0;
  font-size: 14px;
  line-height: 18px;
}

.author {
  font-weight: bold;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
}

.star {
  margin-right: 3px;
  color: #f8284e;
}

@media (min-width: 768px) {
  .wrapper {
    margin-right: 20px;
    border-top: none;
    border-right: 1px rgba(39, 41, 45, 0.1) solid;
  }

  .wrapper:last-child {
    margin-right: 0;
    border-right: none;
    border-bottom: none;
  }
}
