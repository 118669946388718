.landingCtaSection {
  margin: 60px 0;
  text-align: center;
}

.image {
  width: 100%;
  margin-bottom: 40px;
  border-radius: 50%;
}

.ctaButton {
  min-width: 200;
  margin-top: 20;
}

@media only screen and (min-width: 768px) {
  .landingCtaSection {
    text-align: left;
  }

  .image {
    margin-bottom: 0;
  }
}
