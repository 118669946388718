.stepCard {
  margin: 0 auto;
  padding: 16px;
}

.stepCardHeader {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.stepCardIndex {
  flex-shrink: 0;
  width: 32px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #f8284e;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
}

.stepCardTitle {
  margin: unset;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.03em;
}

.stepCardDescription {
  margin: unset;
  color: #595d65;
  line-height: 18px;
  letter-spacing: -0.02em;
}

.stepCardDescriptionHomePage {
  color: #595d65;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
}
