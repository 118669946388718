.bannerWrapper {
  display: flex;
  overflow: hidden;
  width: -moz-available;
  width: -webkit-fill-available;
  min-width: 180px;
  padding: 5px 0;
}

.bannerWrapper:not(:last-of-type) {
  margin-right: 20px;
}

.scrollableStyle {
  min-width: 180px;
}

@media (hover: hover) {
  .bannerWrapper:hover {
    filter: drop-shadow(0 2px 2px rgba(51, 51, 51, 0.4));
  }
}

@media screen and (min-width: 768px) {
  .scrollableStyle {
    min-width: 358px;
  }

  .bannerWrapper:not(.scrollableStyle) {
    min-width: unset;
  }
}
