.categoryTag {
  display: inline-block;
  margin-right: 4px;
  border: none;
  border-radius: 100px;
  color: white;
  white-space: nowrap;
  transition: filter 0.2s ease;
}

.categoryTag:hover {
  color: white;
  filter: brightness(90%);
}

.categoryTag:active {
  filter: brightness(80%);
}

.categoryTagTitle {
  font-weight: 500;
  font-size: 14px;
}

.categoryTagIcon {
  margin: 0 2px 0 6.5px;
}

.categoryTag--large {
  padding: 8.5px 10px;
  line-height: 15px;
}

@media screen and (min-width: 1024px) {
  .categoryTag:last-of-type {
    margin-right: 0;
  }
}
