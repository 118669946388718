.cardsCtaWrapper {
  z-index: 10;
  min-height: 150px;
  margin-top: -120px;
  margin-bottom: -30px;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 0 0 20px;
  background-image: linear-gradient(180deg, var(--colors-primary-400) 150px, var(--colors-secondary-200) 150px);
  font-size: 16px;
}
