.profileCardSecondary {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  font-weight: normal;
  cursor: pointer;
}

.picture {
  position: relative;
  overflow: hidden;
  width: 90px;
  height: 90px;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  min-height: 82%;
  transition: all 300ms ease;
  object-position: top center;
}

.content {
  flex: 1;
}

.priceAndPlayWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px 8px;
}

.price {
  display: inline-block;
  height: 22px;
  padding: 0 8px;
  border-radius: 11px;
  background: rgba(57, 57, 57, 0.6);
  color: white;
  font-weight: bold;
  font-size: 11px;
  line-height: 22px;
}

.playButton {
  height: 35px;
  font-size: 28px;
}

.details {
  min-height: 70px;
  padding: 12px 12px 0;
  background: white;
  text-align: left;
}

.campaignTitle {
  margin: 0;
  padding: 0 0 10px;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
}

.title {
  min-height: 18px;
  margin: 0;
  color: #888;
  font-weight: normal;
  font-size: 11px;
  line-height: 120%;
}

.footer {
  align-self: center;
  padding: 10px;
}

.bookBtn {
  display: block;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  background: #e1e2e9;
  color: var(--colors-secondary-900);
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

@media only screen and (min-width: 700px) {
  .profileCardSecondary:hover {
    transition: all 250ms ease;
  }

  .profileCardSecondary:hover .image {
    transform: scale(1.1);
  }

  .profileCardSecondary:hover .bookBtn {
    background: #d1d2d9;
  }
}
