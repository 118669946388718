.playButton {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

.mediaWrapper + button.playButton.backgroundColor {
  background-color: transparent;
}

.playerWrapper {
  height: 100%;
}

.controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
}

.container {
  height: 100%;
}

.mediaWrapper {
  width: 100%;
  height: 100%;
}

.containerHorizontal {
  display: flex;
  align-items: center;
  width: auto;
  background-color: #151515;
}

.containerHorizontal .playerWrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: auto;
}

.containerHorizontal .mediaWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
}

.muteButton {
  margin-right: 10px;
  border: none;
  background: none;
  color: white;
  outline: none;
  font-size: 24px;
  cursor: pointer;
}

.posterWrapper {
  width: 100%;
  height: 100%;
}

.poster {
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.posterPlaying {
  opacity: 0;
}

.gradientTop::before,
.gradientBottom::after {
  content: ' ';
  position: absolute;
  top: 0;
  z-index: 9;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.gradientTop::before {
  background: linear-gradient(rgba(0, 0, 0, 0.2) 5%, transparent 20%);
}

.gradientBottom::after {
  background: linear-gradient(transparent 70%, rgba(0, 0, 0, 0.6) 90%);
}

.roundedCorners.container,
.roundedCorners video,
.roundedCorners .player,
.roundedCorners.gradientBottom::after,
.roundedCorners.gradientTop::before {
  overflow: hidden;
  border-radius: 8px;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .controls {
    left: -7px;
  }
}
