.card {
  position: relative;
  display: block;
  flex: 0 0 100%;
  overflow: hidden;
  border-radius: 8px;
  color: white;
  font-weight: normal;
  cursor: pointer;
}

.playerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background: #292929;
  object-position: top center;
}

.aspect {
  width: 100%;
  height: 0;
  padding-bottom: 180%;
}

.occasionAndDuration {
  position: absolute;
  top: 8px;
  right: 8px;
  left: 8px;
  z-index: 11;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.occasion {
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 4px 8px 3px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.duration {
  position: absolute;
  top: 2px;
  right: 0;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  min-height: 0;
  text-align: left;
  transition: 0.25s ease;
  transition-property: background, min-height;
}

.contentExpanded {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
}

.details {
  display: block;
  padding: 12px;
  color: white;
}

.detailsWithReview {
  padding: 12px 12px 0 12px;
}

.details:hover {
  color: white;
}

.title {
  margin: 6px 0 0 0;
  font-size: 11px;
  line-height: 120%;
}

.reviewContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 0;
  padding: 0 12px 12px 12px;
  outline: none;
  transition: min-height 0.25s ease;
}

.reviewButton {
  margin-top: 6px;
  color: #95979f;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.reviewButtonExpanded {
  min-height: 360px;
}

.reviewStar,
.star {
  font-size: 14px;
}

.star {
  color: red;
}

.starRating {
  margin-left: 8px;
}

.reviewText {
  margin-bottom: 0;
  font-weight: bold;
  font-style: italic;
  font-size: 15px;
}

.emoji {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.starIcon {
  margin: 0 2px;
  color: var(--semantic-warning);
}

.ratingNumber {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 106%;
  letter-spacing: -0.02em;
}

.ratingContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  order: 0;
  width: 51px;
  margin: 6px 0;
  padding: 5px 5px 4px;
  border-radius: 16px;
  background: #f8284e;
}

.reviewButtonIcon {
  margin-left: 3px;
}

@media only screen and (min-width: 768px) {
  .card:hover {
    transition: all 0.25s ease;
  }
}
