.stepCardSwiperContainer {
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 8px 0 25px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.stepCardSwiperContainer::after {
  content: '';
  display: block;
  flex-shrink: 0;
  width: 6px;
  height: 1px;
}

.stepCardSwiperContainer::-webkit-scrollbar {
  display: none;
}

.stepCardSwiperItem {
  display: flex;
  flex-shrink: 0;
  width: 135px;
  margin-right: 8px;
  border-radius: 16px;
  background-color: hsla(0, 0%, 100%, 0.95);
  box-shadow: -5px 10px 20px rgba(0, 0, 0, 0.05);
}

.stepCardSwiperItem:first-child {
  margin-left: 16px;
}

.stepCardSwiperItem:last-child {
  margin-right: 16px;
}

@media screen and (min-width: 375px) {
  .stepCardSwiperItem {
    width: 160px;
  }

  .homePageStepCard {
    width: 190px;
  }
}

@media screen and (min-width: 800px) {
  .centerStepCards {
    justify-content: center;
  }

  .stepCardSwiperContainer::after {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .stepCardSwiper {
    margin-left: -16px;
  }

  .stepCardSwiperItem:first-child {
    margin-left: unset;
  }
}

@media screen and (max-width: 1067px) and (min-width: 1024px) {
  .stepCardSwiperItem {
    width: 137px;
  }

  .homePageStepCard {
    width: 190px;
  }
}
