.wrapper {
  position: relative;
  height: 100%;
  padding-bottom: 135px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' height='419' viewBox='0 0 1440 419' width='1440' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0-16h1440v686h-1440z' fill='%23f8284e'/%3E%3Cpath d='m2.00001 809h1437.99999v-512.127h-34.6c-107.32-3.991-164.31-26.491-220.24-48.577-59.22-23.38-117.26-46.296-232.589-46.296-117.307 0-173.696 23.707-230.282 47.498-51.599 21.694-103.362 43.456-201.628 47.375h-70.774c-106.147-3.992-162.511-26.492-217.837-48.577-58.571-23.38-115.977-46.296-230.05-46.296z' fill='%23000' opacity='.05'/%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
}

.wrapper > * {
  position: relative;
  z-index: 11;
}

.wave {
  position: absolute;
  bottom: -3px;
  z-index: 10;
  width: 100%;
  height: 33px;
  mask-size: 100% 31px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: bottom;
  -webkit-mask-size: 100% 31px;
  -webkit-mask-position: bottom;
}

@media screen and (min-width: 768px) {
  .wrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    padding-bottom: 165px;
    background-position-y: center;
  }
}
