.card {
  padding: 12px;
  border-radius: 8px;
  background: #fff;
}

.details {
  min-height: 68px;
}

.title {
  margin: 5px 0;
}

.description,
.price {
  color: #888;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
}

.price {
  margin: 10px 0;
}

.formattedPrice {
  color: #111;
  font-weight: 400;
}
