.wrapper {
  display: flex;
  overflow-x: scroll;
  margin-top: 10px;
  margin-bottom: 37px;
  text-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.wrapper > *:first-child {
  margin-left: 20px;
}

@media screen and (min-width: 769px) {
  .wrapper > *:first-child {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding-top: 20px;
  }
}
