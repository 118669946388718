.card {
  position: relative;
  display: block;
  flex: 0 0 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  background-size: cover;
  font-weight: normal;
  cursor: pointer;
}

.cardContent {
  position: relative;
  flex: 0 0 50%;
  padding: 15px;
  text-align: left;
}

.aspect {
  width: 100%;
  height: 0;
  padding-bottom: 180%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.tagline {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 11;
  color: inherit;
  font-size: 11px;
  text-align: left;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: block;
  width: 100%;
  padding: 12px;
  color: inherit;
  text-align: left;
}

.title {
  color: inherit;
}

.description {
  min-height: 18px;
  margin: 6px 0 0 0;
  color: inherit;
  font-weight: normal;
  font-size: 11px;
  line-height: 120%;
}

@media only screen and (min-width: 768px) {
  .card:hover {
    transition: all 250ms ease;
  }
}
