.landingIntro {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  word-break: break-word;
}

.introSection {
  margin: 0 0 25px;
}

.imagesWrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.image {
  width: 100%;
  border-radius: 6px;
}

.imageWrapper {
  position: relative;
  flex: 0 0 58%;
  border: 3px solid #f3f3f3;
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
}

.imageWrapperOne {
  top: -5px;
  left: 10%;
  z-index: 0;
  transform: rotate(-4deg);
}

.imageWrapperTwo {
  top: -2px;
  right: 10%;
  z-index: 0;
  transform: rotate(4deg);
}

@media only screen and (min-width: 768px) {
  .landingIntro {
    flex-direction: row;
    margin: 40px 0;
  }

  .introSection {
    flex: 0 0 65%;
    margin: 60px 0;
    padding-right: 40px;
  }

  .imagesWrapper {
    flex: 0 0 35%;
    margin-top: 20px;
  }

  .imageOne {
    left: 0;
  }

  .imageTwo {
    right: 0;
  }
}
