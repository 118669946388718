.coloredBanner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 45px 0;
  padding: 24px 40px;
  border-radius: 16px;
  background-position-x: 90%;
  background-position-y: 50%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  text-shadow: none;
}

.coloredBannerTitle {
  display: inherit;
  margin-bottom: 5px;
  color: #fff;
  white-space: pre-line;
}

.coloredBannerText {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  white-space: pre-line;
  opacity: 0.8;
}

.coloredBannerImages {
  margin-top: 16px;
}

.coloredBannerImage {
  display: inline-block;
  width: 68px;
  height: 48px;
  margin-right: 4px;
}

.coloredBannerCtaWrapper {
  display: flex;
}

.coloredBannerCta {
  margin-top: auto;
  padding: 10px 32px 12px;
  color: #fff;
  font-size: 16px;
}

.clickableBanner {
  cursor: pointer;
}

.h1 {
  font-size: var(--font-size-h1);
}

.h2 {
  font-size: var(--font-size-h2);
}

.h3 {
  font-size: var(--font-size-h3);
}

.h4 {
  font-size: var(--font-size-h4);
}

.h5 {
  font-size: var(--font-size-h5);
}

.h6 {
  font-size: var(--font-size-h6);
}

@keyframes imageScaleUp {
  0% { background-size: auto 100%; }
  100% { background-size: auto 110%; }
}

@media (hover: hover) {
  @media screen and (min-width: 768px) {
    .clickableBanner:hover > .coloredBanner {
      background-size: auto 110%;
      animation-name: imageScaleUp;
      animation-duration: 0.5s;
    }
  }

  .clickableBanner:hover {
    filter: drop-shadow(0 2px 3px rgba(51, 51, 51, 0.4));
  }
}

@media only screen and (max-width: 768px) {
  .coloredBanner {
    flex-direction: column;
    background-size: 0;
  }

  .coloredBannerTitle {
    font-size: 24px;
  }

  .coloredBannerText {
    font-size: 14px;
    line-height: 16px;
  }

  .coloredBannerCtaWrapper {
    margin-top: 16px;
  }

  .coloredBannerCta {
    font-size: 14px;
  }
}
