.skeletonWrapper {
  display: flex;
  overflow-x: auto;
  margin: 16px 0 40px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (min-width: 1200px) {
  .skeletonWrapper {
    overflow: hidden;
  }
}
