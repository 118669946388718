.section {
  margin-right: calc(-50vw + 50%);
  margin-left: calc(-50vw + 50%);
  padding: 64px 24px;
}

.container {
  max-width: 1084px;
  margin: auto;
}
