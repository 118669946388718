.badge {
  padding: 6px;
  background-color: rgb(248, 40, 78);
  color: white;
  font-size: 14px;
  text-align: center;
}

.badge.dark-red {
  background: var(--colors-secondary-900);
}

.badge.red {
  background: #f8284e;
}

.badge.green {
  background: #1bb55c;
}

.badge.dark-gold {
  background: #303136;
}

.badge.dark-rainbow {
  background: #000;
}

.badge.rainbow {
  background: conic-gradient(from 180deg at 50% 50%, #21cd61 70deg, #3b6fd6 102deg, #e43ebc 256deg, #c4a331 279deg, #1fd15c 316deg);
}

.headline {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
}

.headline.dark-red {
  color: #f8284e;
}

.headline.red {
  color: #fff;
}

.headline.green {
  color: #fff;
}

.headline.dark-gold {
  color: #ffbb12;
}

.headline.dark-rainbow {
  background-image: linear-gradient(#21cd61, #3b6fd6, #e43ebf, #c4a331, #1fd15c);
  background-clip: text;
  color: #e43ebf;
  -webkit-text-fill-color: transparent;
}

.headlineText {
  display: inline;
}

.timerIcon {
  width: 20px;
  margin: 0 5px;
}

.code {
  font-weight: bold;
  font-family: monospace;
}

.countdownWrapper {
  border: 2px white solid;
  border-radius: 25px;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .badge {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .headline {
    font-size: 13px;
  }
}
