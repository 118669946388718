.displayHeading {
  padding: 10px 20px;
  color: white;
  font-size: 28px;
  font-family: var(--font-family-secondary);
  line-height: 1.1;
  letter-spacing: -0.02em;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (min-width: 500px) {
  .displayHeading {
    font-size: 38px;
  }
}

@media screen and (min-width: 768px) {
  .displayHeading {
    max-width: 912px;
    margin: auto;
    font-size: 48px;
    line-height: 50px;
    letter-spacing: -0.02em;
    text-align: center;
  }
}
