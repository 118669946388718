.landingContentSection {
  padding: 50px 0 0;
}

.phoneVideo {
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .landingContentSection {
    padding: 50px 0;
  }

  .image {
    margin-top: 0;
  }
}
