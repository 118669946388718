.videoSection {
  z-index: 2;
  margin-bottom: 30px;
  text-align: center;
}

.videoWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: auto;
  padding-bottom: 197.5%;
}

.videoWrapper::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('/static/img/phone.png');
  background-size: 100%;
  background-repeat: no-repeat;
}

.video {
  position: absolute;
  top: 2%;
  left: 5%;
  z-index: 0;
  object-fit: fill;
  width: 90%;
  height: 96%;
  border-radius: 10px;
}

@media only screen and (min-width: 768px) {
  .videoSection {
    margin-bottom: 0;
  }
}
