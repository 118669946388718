.cardWrapper {
  z-index: 0;
  display: flex;
  flex-shrink: 0;
  width: 200px;
  margin-right: 10px;
  border-radius: 10px;
  background: #fff;
}

.cardWrapperSmall {
  display: flex;
  flex-shrink: 0;
  width: 160px;
  height: 95px;
  margin-right: 10px;
  border-radius: 10px;
  background: #fff;
}

@media screen and (min-width: 768px) {
  .cardWrapper:last-of-type {
    margin-right: 0;
  }
}
