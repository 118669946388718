.card {
  position: relative;
  display: block;
  flex: 0 0 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  background-size: cover;
  font-weight: normal;
  cursor: pointer;
}

.contentOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='160' height='96' viewBox='0 0 160 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.39' fill-rule='evenodd' clip-rule='evenodd' d='M-126.254 318.818L432.306 318.818L432.306 50.1583L432.306 49.8301L418.884 49.8301C377.304 47.7318 355.222 35.9153 333.548 24.3164C310.6 12.0362 288.108 7.35855e-05 243.414 7.74754e-05C197.954 8.1432e-05 176.102 12.4521 154.173 24.9477C134.179 36.3407 114.122 47.7699 76.0496 49.8301L48.5911 49.8301C7.46815 47.731 -14.3714 35.915 -35.8086 24.3168C-58.5062 12.0366 -80.7527 0.000441383 -124.959 0.00044523L-124.959 49.8301L-126.254 49.8301L-126.254 318.818Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='336.693' y1='858.679' x2='336.693' y2='-7.41507e-06' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.661458' stop-color='%2327292D' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%2327292D'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
}

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  display: block;
  width: 100%;
  padding: 0 10px 5px;
  text-align: left;
}

.title {
  color: #fff;
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  font-family: Rubik, -apple-system, 'Helvetica Neue', sans-serif;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

@media only screen and (min-width: 768px) {
  .card:hover {
    transition: all 250ms ease;
  }
}
