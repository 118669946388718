.card {
  display: block;
  width: 130px;
  height: 200px;
  margin: 0;
  margin-right: 10px;
  padding: 6px 10px;
  border: none;
  border-radius: 16px;
  color: white;
  font-weight: 500;
  font-size: 11px;
}

.tag:hover {
  color: white;
  filter: brightness(90%);
}

.tag:active {
  filter: brightness(80%);
}
